import React from "react";

export const SEARCH_ICONS_IDS = {
  arrow: "search_arrow",
  chevron: "search_chevron",
};

export const SEARCH_ICONS = {
  [SEARCH_ICONS_IDS.arrow]: {
    width: "25",
    height: "24",
    color: "#1C2931",
    viewBox: "0 0 25 24",
    icon: ArrowIcon,
  },

  [SEARCH_ICONS_IDS.chevron]: {
    width: "16",
    height: "16",
    color: "#1C2931",
    viewBox: "0 0 16 16",
    icon: ChevronIcon,
  },
};

export function ArrowIcon({ color = "#1C2931" }) {
  return (
    <>
      <path
        d="M4.75683 11.9805H19.7468"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7598 5.98828L19.7968 12.0003L13.7598 18.0123"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}

export function ChevronIcon({ color = "#1C2931" }) {
  return (
    <>
      <path
        d="M5.00333 2L11 8.018L5 14"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  );
}
