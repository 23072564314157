export const FEET_TO_METERS = 3.280839895;
export const METERS_TO_FEET = 1 / FEET_TO_METERS;
export const INCHES_TO_FEET = 12;
export const INCHES_TO_METERS = FEET_TO_METERS * INCHES_TO_FEET;
export const INCHES_TO_CENTIMETERS = 2.54;
export const CENTIMETERS_TO_INCHES = 100 / INCHES_TO_METERS;
export const SQUARE_FEET_TO_METERS = Math.pow(FEET_TO_METERS, 2);
export const CUBIC_FEET_TO_METERS = Math.pow(FEET_TO_METERS, 3);
export const CENTIMETERS_TO_METERS = 100;
export const CENTIMETERS_TO_MILLIMETERS = 10;
export const METERS_TO_MILLIMETERS = 1000;

export const DISTANCE_THRESHOLD = 10;

// TO INCHES
export const METERS_TO_INCHES = 39.37007874;
export const YARDS_TO_INCHES = 36;
export const FEET_TO_INCHES = 12;
export const MILLIMETERS_TO_INCHES = 25.4;
