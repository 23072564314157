import React from "react";

import { SET_ICONS } from "./set_icons";
import { UTIL_ICONS } from "./util_icons";
import { LAYOUT_ICONS } from "./layout_icons";
import { EDITOR_ICONS } from "./editor_icons";
import { MARKUP_ICONS } from "./markup_icons";
import { ORGSWITCHER_ICONS } from "./org_icons";
import { PIPELINE_ICONS } from "./pipeline_icons";
import { COMPARISON_ICONS } from "./comparison_icons";

import Spinner from "../v2/Spinner";
import Tooltip from "../v2/TooltipBase";
import { MODES_IDS } from "../../consts/editor";
import { EXPORT_ICONS } from "./export_icons";
import { SEARCH_ICONS } from "./search_icons";

export const SPINNER_ICON = "spinner";
export const ALL_ICONS: any = {
  ...SET_ICONS,
  ...UTIL_ICONS,
  ...EDITOR_ICONS,
  ...MARKUP_ICONS,
  ...LAYOUT_ICONS,
  ...PIPELINE_ICONS,
  ...COMPARISON_ICONS,
  ...ORGSWITCHER_ICONS,
  ...EXPORT_ICONS,
  ...SEARCH_ICONS,
};

const ALL_ICONS_IDS = Object.keys(ALL_ICONS);

interface IconProps extends React.SVGProps<SVGSVGElement> {
  id: typeof ALL_ICONS_IDS[number];
  width?: number | null;
  height?: number | null;
  viewBox?: string | null;
  color?: string | null;
  color2?: string | null;
  tooltipProps?: any;
  tooltipContent?: string;
  style?: React.CSSProperties;
}

export default function Icon({
  id = MODES_IDS.pan,
  color = null,
  width = null,
  color2 = null,
  height = null,
  viewBox = null,
  tooltipContent = "",
  style = {},
  tooltipProps = {},
  ...props
}: Readonly<IconProps>) {
  const icon: any = ALL_ICONS[id] ?? ALL_ICONS[MODES_IDS.pan];

  return (
    <Tooltip
      side="right"
      sideOffset={10}
      backgroundColor="red"
      content={tooltipContent}
      {...tooltipProps}
    >
      {id === SPINNER_ICON ? (
        <div
          style={{
            display: "flex",
          }}
        >
          <Spinner color={color} size={(width || 16) + "px"} />
        </div>
      ) : (
        <svg
          fill="none"
          width={width || icon.width}
          height={height || icon.height}
          viewBox={viewBox || icon.viewBox}
          xmlns="http://www.w3.org/2000/svg"
          style={style}
          {...props}
        >
          <icon.icon color={color || icon.color} color2={color2} />
        </svg>
      )}
    </Tooltip>
  );
}
